import React from 'react';

const Spacer = (props: {}) => {

  return (
    <>
      <div>
        <hr className="spacer" />
      </div>
    </>
  );
};

export default Spacer;
